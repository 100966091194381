import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/globals';
import { Queue, QueueService } from '../services/queue.service';

export interface Print {
  id?: string;
  UID_RESTAURANT: string;
  created_at: any;
  open_cash_drawer: boolean;
  open_cash_drawer_after_print: boolean;
  value: string;
}

@Injectable()
export class HomeService {

  private printsCol: AngularFirestoreCollection<Print>;
  private prints: Observable<Print[]>;

  constructor(public afs: AngularFirestore, private globals: Globals, private queueService: QueueService) {
  }

  loadPrintService() {
    this.printsCol = this.afs.collection<Print>('print_services', ref => ref
      .where('UID_RESTAURANT', '==', this.globals.restaurant_uid));

    this.prints = this.printsCol.snapshotChanges().pipe(
      map(actions => {
        console.log('changes printer not connected');
        if (this.globals.init_app === false && this.globals.connected === false) {
          if (this.globals.print_delete === false) {
            console.log('printer not connected');
            this.addIssueToQueue();
          }
          this.globals.print_delete = false;

        }
        if (this.globals.init_app === true) {
          this.globals.init_app = false;
        }


        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  getPrints() {
    return this.prints;
  }

  async removePrint(id) {
    return this.printsCol.doc(id).delete();
  }

  // fer probes
  addPrint(print: Print) {
    return this.printsCol.add(print);
  }

  queue: Queue = {
    UID_RESTAURANT: '',
    type: '',
    value_type: '',
    value: '',
  };


  addIssueToQueue() {
    this.queue.UID_RESTAURANT = this.globals.restaurant_uid;
    this.queue.type = 'print';
    this.queue.value_type = 'error';
    this.queue.value = 'Impresora desconectada.';
    this.queueService.addQueue(this.queue).then(() => { });
  }





}
