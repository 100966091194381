import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AngularFireAuth } from 'angularfire2/auth';

import { AuthService } from './services/auth.service';
import { HomeService } from './services/home.service';
import { Globals } from 'src/globals';
import { QueueService } from './services/queue.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public afAuth: AngularFireAuth,
    public navCtrl: NavController,
    private authService: AuthService,
    private homeService: HomeService,
    private globals: Globals,
    private queueService: QueueService) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();

      console.log('APP COMPONENTS');

      this.afAuth.authState.subscribe(userAuth => {
        if (userAuth) {
          console.log('auth');
          this.getUserData(userAuth.uid, userAuth.email);
          this.splashScreen.hide();
        } else {
          console.log('no auth');
          this.navCtrl.navigateForward('login');
          this.splashScreen.hide();
        }
      });



    });
  }

  async getUserData(user_uid, user_email) {
    await this.authService.updateUser(user_uid, user_email);
    if (this.globals.access_user) {
      this.homeService.loadPrintService();
      this.queueService.loadQueueService();
    }
    this.navCtrl.navigateForward('home');
  }




}
