import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Globals } from 'src/globals';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private firestore: any;
  constructor(private globals: Globals) {

    this.firestore = firebase.firestore();
  }


  async updateUser(user_uid, user_email) {
    const col = this.firestore.collection('users');
    const query = col.where('uid_user_auth', '==', user_uid);

    await query.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        var _doc = doc.data();
        _doc.UID = doc.id;
        //save in globals name, email and user_uid
        this.globals.user_name = _doc.name;
        this.globals.user_email = _doc.email;
        this.globals.user_uid = doc.id;
        //globals user_type search user_type
        if (Object.keys(_doc.restaurants).length > 1) {
          this.globals.access_user = false;//no puede usar servicio de impresion
        } else {
          Object.keys(_doc.restaurants).forEach(key => {
            //if type
            if (_doc.restaurants[key].rol == 'principal') {
              this.globals.access_user = false;//no puede usar servicio de impresion
              this.globals.user_type = 'principal';
              this.globals.school_uid = _doc.restaurants[key].UID_SCHOOL;
            } else if (_doc.restaurants[key].rol == 'teacher') {
              this.globals.access_user = false;//no puede usar servicio de impresion
              this.globals.user_type = 'teacher';
              this.globals.school_uid = _doc.restaurants[key].UID_SCHOOL;
            } else {
              this.globals.access_user = true;//puede usar servicio de impresion
              this.globals.user_type = 'indv';
            }
            //get restaurant UID
            let uid = _doc.restaurants[key].UID_RESTAURANT;
            this.globals.restaurant_uid = uid;
            //get rol user in restarant
            let _rol = _doc.restaurants[key].rol;
            this.globals.user_rol = _rol;

            console.log(uid);
            console.log(this.globals.restaurant_uid);

          });
        }
      });
    });
  }


}
