import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from 'src/globals';

export interface Queue {
  id?: string;
  UID_RESTAURANT: string;
  type: any;
  value_type: string;
  value: string;
}



@Injectable()
export class QueueService {

  private queueCol: AngularFirestoreCollection<Queue>;
  private queues: Observable<Queue[]>;

  constructor(public afs: AngularFirestore, private globals: Globals) {

  }

  loadQueueService() {
    this.queueCol = this.afs.collection<Queue>('_queue', ref => ref
      .where('UID_RESTAURANT', '==', this.globals.restaurant_uid));

    this.queues = this.queueCol.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  addQueue(queue: Queue) {
    console.log(queue);
    return this.queueCol.add(queue);
  }

}
