import { Component, Injectable } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';

@Injectable()

export class Globals {
    user_name: any;
    user_email: any;
    user_uid: any;
    school_uid: any;
    user_type: any;
    restaurant_uid: any;
    user_rol: any;
    access_user: any;
    connected: boolean = false;
    init_app: boolean = true;
    print_delete:boolean = false;

    constructor() {
    }
}